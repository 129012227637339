.middle {
	transition: 0.5s ease;
	opacity: 0;
	position: absolute;
	top: 30%;
	left: 25%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	text-align: center;
}

.card-img-top {
	width: 100%;
	height: 35vw;
}

.flex-span {
	display: flex;
 	flex-direction: column;
}
.custom-label {
	display: flex;
	flex-direction: row;
	margin-top: 1rem;
	margin-bottom: 1rem;
}
.margin-top {
	margin-top: 2rem;
}

.margin-auto {
	margin: auto;
}

.stats-custom{
	margin-left: 2rem;
	margin-top: 2rem;
	display: flex;
	flex-direction: column;
}

.pill-buttons {
	border-radius: 16px;
	padding: 10px 20px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	margin: 4px 2px;
}

.pill-buttons-screen-mic {
	position: absolute;
	left: 45%;
	top: 87%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	background-color: #555;
	color: red;
	font-size: 16px;
	padding: 17px 25px;
	border: none;
	cursor: pointer;
	border-radius: 50%;
}

.pill-buttons-screen-mic:hover {
	background-color: transparent;
}

.pill-buttons-screen-video {
	position: absolute;
	left: 56%;
	top: 87%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	background-color: #555;
	color: red;
	font-size: 16px;
	padding: 17px 25px;
	border: none;
	cursor: pointer;
	border-radius: 50%;
}

.pill-buttons-screen-video:hover {
	background-color: transparent;
}

.right-aligned-elements {
	background-color: #fff;
	border-bottom-left-radius: 8px;
	display: flex;
}

.user-card-avatar {
	float: left;
}

.theme-color {
	color: #7367f0;
}

.danger-color {
	color: #dc3545;
}

.company-switch {
	text-align: center;
}

.container-public {
	padding: 50px;
}
.divider-dashboard {
	color: #7367f0;
	width: 100%;
}
.app-navigator-container {
	height: 80vh;
}

.app-navigator-btn > span {
	font-size: 24px;
	display: block;
	margin-top: 15px;
}
.app-navigator-btn > img {
	width: 90px;
}

.bravo-logo-width > img{
	width: 51%;
    margin-bottom: 0.5rem;
}
.search-clear {
	background-color: transparent !important;
    position: absolute;
    right: 3px;
    top: 8px;
    margin-right: 10px;
    z-index: 2;
    border: none;
    cursor: pointer;
    transform: translateX(0px);
    color: darkgray;
	outline: none;
}

.search-clear:focus {
	outline: none;
}

.search-input {
	margin: 0px;
    padding: 5px;
    width: 100%;
    outline: none;
}

.search-group {
	position: relative;
}

.stats-button {
	display: flex;
	flex-direction: column-reverse;
}

.min-height {
	min-height: 25rem;
}

.consent-div {
	margin: 1rem;
	line-height: 2rem;
  }
.consent-header {
	width: 100%;
	font-size: 18px;
	text-align: center;
	padding: 5px;
	margin: 1rem;
}
/* The Modal (background) */
.modal-compliance {
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
  }

.compliance-modal-content {
	position: relative;
	padding: 20px;
	overflow-y: auto;
	overflow-x: hidden;
	height: inherit;
	width: inherit;
}

.custom-spinner {
	margin-right: 10px;
	margin-bottom: 2px
}

.logo-width {
	width: 20%;
}

.logo-new-width {
	width: 30%;
}

.custom-font {
	font-size: calc((16vw - 4.5rem) / 7)
}

@media (min-width:270px) and (max-width: 992px){
	.footer-font-size {
		font-size: smaller;
	}
	.custom-font {
		font-size: 1.5rem;
	}
	.footer-static {
		text-align: center;
	}
}

#column-date, #column-ip, #column-description,
#column-video_call, #column-chat, #column-whatsapp, #column-email, #column-actions, #column-start, #column-end, #column-defaultApplication,
#column-created, #column-lastOnline, #column-lastBookingDate {
	cursor: default;
}

.error-page {
	background-color: white;
	min-height: calc(100vh - 3.35rem) !important;
	height: calc(100vh - 3.35rem) !important;
  }

.img-width {
	width: 63%;
}

.w-33 {
	width: 33%;
}

.logo-height {
	height: 15vh;;
}

@media only screen and (max-width: 990px) {
	.responsive-font p{
		font-size: x-large;
	}
	.responsive-font h4 {
		font-size: small;
	}
	.error-page .brand-logo {
		left: auto !important;
	}
}
